<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="#5562EA"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5274 10C11.9662 10.0549 10.4605 10.5931 9.21818 11.5404C7.80815 14.1838 7.04779 17.1249 7 20.1204C7 20.1204 8.22462 22.2423 11.6212 22.3232C11.6212 22.3232 12.118 21.6608 12.6109 21.0716C10.7008 20.5132 9.96142 19.3579 9.96142 19.3579L10.5968 19.6853C10.7111 19.7324 10.8258 19.7802 10.9412 19.8283C12.7117 20.5666 14.6517 21.3756 17.9561 20.8406C18.718 20.6987 19.4637 20.4806 20.182 20.1897C20.7752 19.9203 21.3537 19.6195 21.9149 19.2886C21.9149 19.2886 21.1717 20.4324 19.1807 21.0023C19.5928 21.5684 20.1666 22.2231 20.1666 22.2231C22.9405 22.1635 24.3317 20.7366 24.7908 20.2657C24.8958 20.158 24.952 20.1004 24.9649 20.1204C24.9061 17.1287 24.1351 14.194 22.7159 11.5597C21.4952 10.6241 20.0164 10.0863 18.4798 10.0193L18.2526 10.2426C20.8174 10.9628 22.0613 12.118 22.0613 12.118C20.2989 11.231 18.3682 10.7286 16.397 10.6443C14.4259 10.56 12.4593 10.8956 10.6276 11.629C10.05 11.8793 9.71881 12.041 9.71881 12.041C9.71881 12.041 10.9588 10.8164 13.693 10.1656L13.5274 10ZM13.1153 18.6417C13.5341 18.6213 13.9291 18.4406 14.2183 18.137C14.5075 17.8334 14.6689 17.4301 14.6689 17.0108C14.6689 16.5915 14.5075 16.1882 14.2183 15.8846C13.9291 15.581 13.5341 15.4002 13.1153 15.3799C12.6833 15.3799 12.2689 15.5515 11.9634 15.857C11.658 16.1625 11.4863 16.5768 11.4863 17.0089C11.4863 17.4409 11.658 17.8552 11.9634 18.1607C12.2689 18.4662 12.6833 18.6378 13.1153 18.6378V18.6417ZM18.7462 18.6417C19.165 18.6213 19.5599 18.4406 19.8492 18.137C20.1384 17.8334 20.2997 17.4301 20.2997 17.0108C20.2997 16.5915 20.1384 16.1882 19.8492 15.8846C19.5599 15.581 19.165 15.4002 18.7462 15.3799C18.3141 15.3799 17.8998 15.5515 17.5943 15.857C17.2888 16.1625 17.1172 16.5768 17.1172 17.0089C17.1172 17.4409 17.2888 17.8552 17.5943 18.1607C17.8998 18.4662 18.3141 18.6378 18.7462 18.6378V18.6417Z"
    />
  </svg>
</template>
