import TwitterLogoIcon from '#/elements/icons/TwitterLogoIcon.vue';
import TelegramLogoIcon from '#/elements/icons/TelegramLogoIcon.vue';
import DiscordLogoIcon from '#/elements/icons/DiscordLogoIcon.vue';
import GoogleLogoIcon from '#/elements/icons/GoogleLogoIcon.vue';
import FacebookLogoIcon from '#/elements/icons/FacebookLogoIcon.vue';
import LinkedInLogoIcon from '#/elements/icons/LinkedInLogoIcon.vue';

export const twitter = {
  name: 'X (Twitter)',
  icon_component: TwitterLogoIcon,
  icon_static: '/logos/twitter-logo.svg',
  color: '#41ABE1'
}
export const telegram = {
  name: 'Telegram',
  icon_component: TelegramLogoIcon,
  icon_static: '/logos/telegram-logo.svg',
  color: '#22ABF9'
}
export const discord = {
  name: 'Discord',
  icon_component: DiscordLogoIcon,
  icon_static: '/logos/discord-logo.svg',
  color: '#5562EA'
}
export const google = {
  name: 'Google',
  icon_component: GoogleLogoIcon,
  icon_static: '/logos/google-logo.svg',
  color: '#4285F4'
}
export const facebook = {
  name: 'Facebook',
  icon_component: FacebookLogoIcon,
  icon_static: '/logos/facebook-logo.svg',
  color: '#1080F8'
}
export const linkedin = {
  name: 'LinkedIn',
  icon_component: LinkedInLogoIcon,
  icon_static: '/logos/linkedin-logo.svg',
  color: '#0A66C2'
}