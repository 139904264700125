import { defineStore } from 'pinia';

export const Themes = Object.freeze({
  White: 'white',
  Dark: 'dark',
});

const LocalStorageThemeKey = 'cc.app_theme';

export const useThemeStore = defineStore('theme', {
  state: () => {
    let theme = localStorage.getItem(LocalStorageThemeKey);
    if (!theme) {
      theme = window.matchMedia('(prefers-color-scheme: dark)').matches
        ? Themes.Dark
        : Themes.White;

      localStorage.setItem(LocalStorageThemeKey, theme);
    }

    if (theme === Themes.Dark) {
      
      document.documentElement.classList.add('dark');
    }

    return {
      /** @type {'white' | 'dark'} */
      theme: theme,
    };
  },
  getters: {
    isWhite: ({ theme }) => theme === Themes.White,
    isDark: ({ theme }) => theme === Themes.Dark,
  },
  actions: {
    /**
     * @param {'white' | 'dark'}  theme
     */
    setTheme(theme) {
      this.theme = theme;
      localStorage.setItem(LocalStorageThemeKey, theme);
      // add or remove 'dark' class
      document.documentElement.classList[
        theme === Themes.Dark ? 'add' : 'remove'
      ]('dark');
    },
  },
});
