import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import Toast from 'vue-toastification';

import App from '#/App.vue';
import AppConfig from '#/config';
import Router from '#/router';
import GlobalSvgDefsPlugin from '#/utils/global-svg-defs';

import 'vue-toastification/dist/index.css';

import '#/style.css';

const app = createApp(App);

if (AppConfig.SENTRY_ENABLE) {
  if (AppConfig.SENTRY_DNS) {
    Sentry.init({
      app: app,
      dsn: AppConfig.SENTRY_DNS,
      release: `cardano.calendar.frontend@${AppConfig.APP_VERSION}`,
      environment: AppConfig.MODE,
      integrations: [
        Sentry.browserTracingIntegration({ router: Router }),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', AppConfig.API_URI],
      // Session Replay
      replaysSessionSampleRate: AppConfig.PROD ? 0.1 : 1.0, // This sets the sample rate at 100% while in development and 10% while in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } else {
    console.warn(
      "Cannot initialize Sentry without DNS. Set 'VITE_SENTRY_DNS' in your .env file or set 'VITE_SENTRY_ENABLE' to `false` to suppress this warning.",
    );
  }
}

app.use(createPinia());
app.use(Router);
app.use(GlobalSvgDefsPlugin);
app.use(Toast, {
  transition: 'Vue-Toastification__fade',
});
app.mount('#app');

console.log(
  `Cardano Calendar ${AppConfig.APP_VERSION} <${AppConfig.MODE} mode>`,
);
