export class NoAccessError extends Error {
  constructor(message = 'No access to perform action', options) {
    super(message, options);
    this.name = 'NoAccessError';
  }
}

export class ApiError extends Error {
  constructor({ code = "unknown", message = 'Unknown error' } = {}, options) {
    super(message, options);
    this.name = code;
    this.code = code;
  }
}
