<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="#22ABF9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.92 9.73862L8.7471 15.204C7.77986 15.5924 7.78545 16.132 8.56964 16.3726L12.2084 17.5077L20.6274 12.1959C21.0255 11.9537 21.3892 12.084 21.0903 12.3494L14.2692 18.5054H14.2676L14.2692 18.5062L14.0182 22.2568C14.3859 22.2568 14.5482 22.0882 14.7544 21.8891L16.5218 20.1705L20.1982 22.8859C20.876 23.2593 21.3628 23.0674 21.5315 22.2584L23.9448 10.8849C24.1918 9.8945 23.5667 9.44605 22.92 9.73862V9.73862Z"
    />
  </svg>
</template>
