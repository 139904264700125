import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/admin/events',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

export async function list({
  user_id,
  startAfter,
  endBefore,
  search,
  limit,
  offest,
  filters,
  sort,
} = {}) {
  const response = await instance.get('', {
    params: {
      userId: user_id,
      take: limit,
      skip: offest,
      filters: filters || {
        userId: {
          $eq: user_id,
        },
        startTimestamp: {
          $gte: startAfter,
        },
        endTimestamp: {
          $lte: endBefore,
        },
        $or: [
          { title: { $contains: search } },
          { user: { fullName: { $contains: search } } },
        ],
      },
      sort: sort && `sort${sort.key} ${sort.order}`,
    },
  });
  return response.data;
}

export async function get(event_id) {
  const response = await instance.get(`/${event_id}`);
  return response.data;
}

export async function getUsers(event_id, { search, limit, offest } = {}) {
  const response = await instance.get(`/${event_id}/users`, {
    params: {
      take: limit,
      skip: offest,
      filters: {
        fullName: {
          $contains: search,
        },
      },
    },
  });
  return response.data;
}
export async function getReports(event_id, { limit, offest } = {}) {
  const response = await instance.get(`/${event_id}/reports`, {
    params: {
      take: limit,
      skip: offest,
    },
  });
  return response.data;
}

export async function block(event_id, data) {
  const response = await instance.put(`/${event_id}/blocking-data`, data);
  return response.data;
}
