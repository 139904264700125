<script setup>
import EventsDisplayMode from '#/components/EventsDisplayMode.vue';
import JoinOurCommunity from '#/components/JoinOurCommunity.vue';
import useTitle from '#/utils/use-title.js';

useTitle({ subtitle: 'Events' });
</script>

<template>
  <main class="flex flex-col gap-8">
    <div class="px-4 sm:px-8 xl:px-10">
      <div class="mx-auto flex w-full max-w-xxl items-center justify-between">
        <h1 class="text-h-medium font-bold md:text-h-large">
          Events
        </h1>
        <nav
          class="flex gap-4 sm:gap-2"
          aria-label="Event display mode"
        >
          <RouterLink
            v-slot="{ isActive }"
            :to="{ name: 'Calendar' }"
          >
            <EventsDisplayMode
              type="calendar"
              :active="isActive"
            />
          </RouterLink>
          <RouterLink
            v-slot="{ isActive }"
            :to="{ name: 'EventGrid' }"
          >
            <EventsDisplayMode
              type="grid"
              :active="isActive"
            />
          </RouterLink>
        </nav>
      </div>
      <div class="mx-auto mt-8 w-full max-w-xxl">
        <RouterView />
      </div>
    </div>

    <JoinOurCommunity />
  </main>
</template>
