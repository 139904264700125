<script setup>
import { defineAsyncComponent } from 'vue';
import GlobalSVGDefsRoot from '#/utils/global-svg-defs/GlobalSVGDefsRoot.vue';

const DevControlsComp = defineAsyncComponent(async () => {
  if (import.meta.env.DEV) {
    const { default: comp } = await import(
      '#/components/dev-controls/DevControls.vue'
    );
    return comp;
  } else {
    return null;
  }
});
</script>

<template>
  <DevControlsComp />
  <router-view />
  <GlobalSVGDefsRoot />
</template>
