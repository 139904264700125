import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/tags',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * List of all keywords
 * @returns {Promise<Array<CardanoKeyword>>}
 */
export async function list() {
  const response = await instance.get('');
  return response.data;
}
/**
 * List of keywords of an event
 * @returns {Promise<Array<CardanoKeyword>>}
 */
export async function getForEvent(event_id) {
  const response = await instance.get('', { params: { eventId: event_id } });
  return response.data;
}
/**
 * List of keywords of a user
 * @returns {Promise<Array<CardanoKeyword>>}
 */
export async function getForUser(user_id) {
  const response = await instance.get('', { params: { userId: user_id } });
  return response.data;
}

/**
 * Get a keyword by id
 * @param {number} keyword_id
 * @returns {Promise<CardanoKeyword>}
 */
export async function get(keyword_id) {
  const response = await instance.post(`/${keyword_id}`);
  return response.data;
}
/**
 * Create new keyword. Admin only.
 * @param {string} name
 * @returns {Promise<CardanoKeyword>}
 */
export async function create(name) {
  const response = await instance.post('', { name });
  return response.data;
}
/**
 * Update a keyword. Admin only.
 * @param {number} keyword_id
 * @param {string} name
 * @returns {Promise<CardanoKeyword>}
 */
export async function update(keyword_id, name) {
  const response = await instance.put(`/${keyword_id}`, { name });
  return response.data;
}
/**
 * Delete a keyword. Admin only.
 * @param {number} keyword_id
 */
export async function remove(keyword_id) {
  const response = await instance.delete(`/${keyword_id}`);
  return response.data;
}
