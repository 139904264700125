<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.74999 7.12506C2.74999 8.27 3.14262 9.10917 3.77714 9.92608L4.32399 10.4729C4.43565 10.5596 4.52141 10.6294 4.63242 10.7113C5.0725 11.0362 5.61544 11.2441 6.15127 11.38L7.12495 11.5019C7.25507 11.5019 7.24807 11.4787 7.37107 11.4728C8.69298 11.4086 9.97618 10.7171 10.7071 9.61346C11.7578 8.0267 11.7578 6.22341 10.7071 4.63671C10.4537 4.25407 9.99594 3.79635 9.6133 3.54296C8.92976 3.09029 8.21474 2.79928 7.37031 2.77816C7.23596 2.77479 7.24036 2.74982 7.12495 2.74982C7.00949 2.74982 7.01394 2.77479 6.87959 2.77816C6.35163 2.7914 5.79322 2.92559 5.32926 3.14186C4.77801 3.39878 4.14664 3.8483 3.77708 4.32409C3.69039 4.43575 3.62064 4.52152 3.53867 4.63253C3.06955 5.26803 2.74999 6.24963 2.74999 7.12506V7.12506ZM13.25 7.01568C13.25 7.9474 13.1451 8.47357 12.8778 9.24113C12.5546 10.1689 12.2736 10.3607 12.0469 10.7891L14.3779 13.1338C14.7577 13.5137 15 13.6282 15 14.2071C15 14.5867 14.5867 15 14.207 15C13.6945 15 13.5541 14.827 13.1201 14.3916L10.7617 12.0742C10.6634 12.1005 9.26567 13.25 7.28905 13.25C6.25126 13.25 5.36091 13.0959 4.56376 12.6941C3.67063 12.2439 3.51875 12.1115 2.79765 11.4797L2.28885 10.8948C2.23087 10.8168 2.18245 10.7563 2.12649 10.6743C1.47552 9.72072 1 8.57991 1 7.31646C1 6.2448 1.13929 5.3925 1.56509 4.54557C1.73337 4.21086 1.75047 4.15202 1.95164 3.83837L2.78353 2.78353L3.80471 1.97265C4.9661 1.2642 5.87405 1 7.2891 1C8.12757 1 9.13995 1.28016 9.70466 1.56493C10.4737 1.95273 10.876 2.22165 11.4658 2.81154C12.5534 3.89916 13.25 5.40509 13.25 7.01568V7.01568Z"
    />
  </svg>
</template>
