import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/geolocation',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

export async function countries() {
  const response = await instance.get('/countries');
  return response.data;
}
export async function country(country_id) {
  const response = await instance.get(`/countries/${country_id}`);
  return response.data;
}
export async function states(countryId) {
  const response = await instance.get('/states', { params: { countryId } });
  return response.data;
}
export async function state(stateId) {
  const response = await instance.get(`/states/${stateId}`);
  return response.data;
}
export async function cities(stateId) {
  const response = await instance.get('/cities', { params: { stateId } });
  return response.data;
}
export async function city(cityId) {
  const response = await instance.get(`/cities/${cityId}`);
  return response.data;
}
