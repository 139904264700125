<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="fill-none stroke-primary stroke-2"
    stroke-linecap="round"
    stroke-linejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0005 19.0002C15.0005 19.7959 14.6844 20.5589 14.1218 21.1215C13.5592 21.6842 12.7961 22.0002 12.0005 22.0002C11.2048 22.0002 10.4417 21.6842 9.87911 21.1215C9.31649 20.5589 9.00042 19.7959 9.00042 19.0002M12.7215 5.00302L11.2554 5.00002C7.91141 4.99202 5.00837 7.70905 4.98537 11.0001V14.7901C4.98537 15.5802 4.88537 16.3512 4.45436 17.0082L4.16736 17.4462C3.73035 18.1102 4.20036 19.0002 4.98537 19.0002H19.0155C19.8006 19.0002 20.2696 18.1102 19.8336 17.4462L19.5466 17.0082C19.1166 16.3512 19.0155 15.5792 19.0155 14.7891V11.0011C18.9755 7.70905 16.0655 5.01102 12.7215 5.00302V5.00302Z"
    />
    <path
      d="M12.0005 2C12.531 2 13.0397 2.21072 13.4147 2.58579C13.7898 2.96087 14.0005 3.46959 14.0005 4.00003V5.00004H10.0005V4.00003C10.0005 3.46959 10.2112 2.96087 10.5863 2.58579C10.9614 2.21072 11.4701 2 12.0005 2Z"
    />
  </svg>
</template>
