<script setup>
import Toggle from '#/components/Toggle.vue';
import { Themes, useThemeStore } from '#/store/theme';

const store = useThemeStore();
</script>

<template>
  <Toggle
    :model-value="store.isDark"
    active-icon="/icons/dark-mode.svg"
    inactive-icon="/icons/white-mode.svg"
    aria-label="Enable dark theme"
    @update:model-value="store.setTheme($event ? Themes.Dark : Themes.White)"
  />
</template>
