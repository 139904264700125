<template>
  <GlobalSVGDefs id="cardano_logo_lg_1">
    <linearGradient
      id="cardano_logo_blue"
      x1="15.4797"
      y1="0"
      x2="15.4797"
      y2="36"
      gradientUnits="userSpaceOnUse"
    >
      <stop class="cardano-calendar-logo-gradient-start" /><stop
        class="cardano-calendar-logo-gradient-end"
        offset="1"
      />
    </linearGradient>
  </GlobalSVGDefs>
</template>
