import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/user',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @returns {Promise<CardanoUser>}
 */
export async function getUser() {
  const response = await instance.get('');
  return response.data;
}
/**
 * @param {CardanoUserInfo} data
 * @returns {Promise<CardanoUser>}
 */
export async function updateUser(data) {
  const response = await instance.post('', data);
  return response.data;
}
/**
 * Delete user's account
 */
export async function deleteUser() {
  const response = await instance.delete('');
  return response.data;
}

/**
 * @param {Array<number>} keyword_ids
 */
export async function setKeywords(keyword_ids) {
  const response = await instance.post('/tags', keyword_ids);
  return response.data;
}

export async function requestEmailVerification() {
  try {
    const response = await instance.post('/email/verification/request');
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}

/**
 * @param {string} nonce base64url encoded string
 */
export async function submitEmailVerification(nonce) {
  try {
    const response = await instance.post('/email/verification/submit', {
      payload: nonce,
    });
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}

/**
 * @param {string} email new email address
 */
export async function requestEmailChange(email) {
  try {
    const response = await instance.post('/email/change/request', {
      email,
    });
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}

/**
 * @param {string} nonce base64url encoded string
 */
export async function submitEmailChange(nonce) {
  try {
    const response = await instance.post('/email/change/submit', {
      payload: nonce,
    });
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}

export async function getOAuth1TwitterConnectLink() {
  return instance.defaults.baseURL + '/connect/twitter';
}
export async function getOAuth2GoogleConnectLink(params) {
  return (
    instance.defaults.baseURL + '/connect/google?' + new URLSearchParams(params)
  );
}
/**
 * @returns {Promise<Array<CardanoOAuth1Session>>}
 */
export async function getOAuth1Sessions() {
  try {
    const response = await instance.get('/oauth1');
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}
/**
 * @returns {Promise<Array<CardanoOAuth2Session>>}
 */
export async function getOAuth2Sessions() {
  try {
    const response = await instance.get('/oauth2');
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}
/**
 * @param {string} provider
 */
export async function deleteOAuth1Sessions(provider) {
  try {
    const response = await instance.delete('/oauth1/' + provider);
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}
/**
 * @param {string} provider
 */
export async function deleteOAuth2Sessions(provider) {
  try {
    const response = await instance.delete('/oauth2/' + provider);
    return response.data;
  } catch (error) {
    throwRealErrorMessage(error);
  }
}

function throwRealErrorMessage(error) {
  let message = error.message;
  if (error.response) {
    message = error.response.data.message;
  }
  throw new Error(message);
}
