import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/nft',
  headers: { Accept: 'application/json' },
  // withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * Validate NFT to be used to create a featured event
 * @param {Array<string>} nfts
 * @returns {Promise<NFTValidationResult>}
 */
export async function validate(nfts) {
  const response = await instance.post('/validate', nfts);
  return response.data;
}

/**
 * @returns {Promise<EpochInfo>}
 */
export async function getEpochInfo() {
  const response = await instance.get('/epochs/latest');
  return response.data;
}
