<template>
  <svg
    width="32"
    height="32"
    fill="#000000"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.1439 6.73047L13.7917 16.9561L6.0957 25.2701H7.8279L14.5659 17.9909L20.0098 25.2701H25.9042L17.8259 14.4694L24.9894 6.73047H23.2572L17.052 13.4342L12.0382 6.73047H6.1439ZM8.69118 8.00627H11.399L23.3565 23.9943H20.6487L8.69118 8.00627Z"
    />
  </svg>
</template>
