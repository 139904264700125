import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/notifications',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @param {{ limit: number, offset: number, filters: { isRead: { $eq: boolean }} }} params
 * @returns {Promise<{ rows: Array<CardanoNotification>, count: number }>}
 */
export async function list(params) {
  const { limit, offset, filters } = params;
  const response = await instance.get('', {
    params: {
      take: limit,
      skip: offset,
      filters: filters
        ? {
            UserNotification: {
              $some: filters,
            },
          }
        : undefined,
    },
  });
  return response.data;
}

export async function read(notification_id) {
  const response = await instance.get(`/${notification_id}/read`);
  return response.data;
}
