import { inject, reactive } from 'vue';

export const GlobalSVGDefsInjectionKey = Symbol('GlobalSVGDefsInjectionKey');

export function createContext() {
  const elements = reactive(new Map());
  const usage = reactive(new Map());

  function registerElement(key, element) {
    if (!elements.has(key)) {
      elements.set(key, element);
    }

    usage.set(key, (usage.get(key) || 0) + 1);
  }
  function unregisterElement(key) {
    let usageCounter = usage.get(key);
    if (!usageCounter) return;

    usageCounter -= 1;
    if (usageCounter > 0) {
      usage.set(key, usageCounter);
    } else {
      elements.delete(key);
      usage.delete(key);
    }
  }

  return {
    elements,
    usage,
    registerElement,
    unregisterElement,
  };
}

/**
 * @returns {ReturnType<typeof createContext>}
 */
export function useContext() {
  const context = inject(GlobalSVGDefsInjectionKey);
  if (!context) throw new Error('No GlobalSVGDefsContext');
  return context;
}
