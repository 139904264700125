import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/reviews',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @param {number} eventId
 * @param {CardanoCreateNotAttendedReview | CardanoCreateAttendedReview} data
 * @returns {Promise<CardanoReport>}
 */
export async function create(
  eventId,
  { attended, comment, code, mark, skippingReason = null },
) {
  const result = await instance.post('', {
    eventId,
    attended,
    comment,
    code,
    mark,
    skippingReason,
  });
  return result.data;
}

/**
 * @param {number} id
 * @returns {Promise<CardanoReport>}
 */
export async function get(id) {
  const result = await instance.get(`/${id}`);
  return result.data;
}

/**
 * @param {number} id
 * @param {object} data
 * @param {number} data.eventId
 * @param {string} data.comment
 * @param {string} data.code
 * @returns {Promise<CardanoReport>}
 */
export async function set(id, data) {
  const result = await instance.put(`/${id}`, data);
  return result.data;
}

/**
 * @param {number} id
 * @returns {Promise<void>}
 */
export async function remove(id) {
  await instance.delete(`/${id}`);
}
