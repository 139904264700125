import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/comments',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * @returns {Promise<CardanoComment>}
 */
export async function create(eventId, text, commentId = null) {
  const response = await instance.post('', {
    eventId,
    text,
    commentId,
  });
  return mapComment(response.data);
}
/**
 * @returns {Promise<CardanoComment | { replies: Array<CardanoComment>}>}
 */
export async function get(commentId) {
  const response = await instance.get(`/${commentId}`);
  return {
    ...mapComment(response.data),
    replies: response.data.replies.map(mapComment),
  };
}
/**
 * @returns {Promise<CardanoComment>}
 */
export async function update(commentId, eventId, text, replyTo = null) {
  const response = await instance.patch(`/${commentId}`, {
    eventId,
    text,
    commentId: replyTo,
  });
  return mapComment(response.data);
}
export async function remove(commentId) {
  const response = await instance.delete(`/${commentId}`);
  return response.data;
}
/**
 * @returns {Promise<{ data: Array<CardanoComment | { replies: number }>, total: number }>}
 */
export async function list(eventId, { offset, limit } = {}) {
  const response = await instance.get('', {
    params: { eventId, skip: offset, take: limit },
  });
  return {
    data: response.data.rows.map((item) => ({
      ...mapComment(item),
      replies: item._count.replies,
    })),
    total: response.data.count,
  };
}
/**
 * @returns {Promise<Array<CardanoComment>>}
 */
export async function getReplies(commentId) {
  const response = await instance.get(`/${commentId}/replies`);
  return response.data.map(mapComment);
}

function mapComment(raw_comment) {
  return {
    id: raw_comment.id,
    eventId: raw_comment.eventId,
    text: raw_comment.text,
    commentId: raw_comment.commentId,
    timestamp: raw_comment.createdAt,
    user: {
      id: raw_comment.userId,
      avatar: raw_comment.user?.avatar,
      name: raw_comment.user?.fullName,
      isVerified: raw_comment.user?.isVerified,
    },
  };
}
