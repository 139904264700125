<script setup>
import UserIcon from '#/elements/icons/UserIcon.vue';
import VerifiedIcon from '#/elements/icons/VerifiedIcon.vue';
import { useUserStore } from '#/store/user';

const userStore = useUserStore();
</script>

<template>
  <div class="flex w-full flex-row items-center justify-between gap-2">
    <div class="flex flex-auto items-center gap-2 truncate">
      <template v-if="!userStore.isAuthorized">
        <!-- Loading skeleton -->
        <div
          class="skeleton-block size-16 shrink-0 rounded-full xl:size-[5.5rem]"
          aria-hidden="true"
        />
        <span
          class="skeleton-text w-1/2 text-xl"
          aria-hidden="true"
        />
      </template>
      <template v-else>
        <!-- User avatar -->
        <img
          v-if="userStore.avatarUrl"
          class="size-16 shrink-0 rounded-full object-cover xl:size-[5.5rem]"
          :src="userStore.avatarUrl"
          alt="Your avatar"
          aria-label="Your avatar"
        >
        <!-- Fallback avatar -->
        <div
          v-else
          class="size-16 shrink-0 rounded-full border border-gray-400 xl:size-[5.5rem]"
          aria-label="Avatar placeholder"
        >
          <UserIcon
            class="m-auto h-full w-12 stroke-gray-400 stroke-1"
            aria-hidden="true"
          />
        </div>
        <!-- Username -->
        <span class="truncate text-xl font-semibold text-white">
          {{ userStore.fullName }}
        </span>
        <!-- Verified Icon -->
        <VerifiedIcon
          v-if="userStore.isEmailVerified"
          aria-label="Account is Verified"
          alt="Check icon in circle"
        />
      </template>
    </div>
  </div>
</template>
