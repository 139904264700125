import axios from 'axios';
import useReportErrorInterceptor from '../utils/report-error';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URI + '/uploads',
  headers: { Accept: 'application/json' },
  withCredentials: true,
});
useReportErrorInterceptor(instance);

/**
 * Upload a file to the S3 Bucket
 * @param {File} file
 */
export async function upload(file) {
  const formData = new FormData();
  formData.append('upload', file);
  const response = await instance.post('', formData);
  return response.data;
}

/**
 * Delete files from S3 Bucket by url
 * @param {Array<string>} file_urls
 */
export async function remove(file_urls) {
  const response = await instance.delete('', {
    data: file_urls,
  });
  return response.data;
}
