export function ReportErrorInterceptor(error) {
  if (error.response?.data?.message) {
    console.error('Error report:', error.response.data.message);
  }
  throw error;
}

/**
 * @param {import("axios").AxiosInstance} axios
 */
export default function useReportErrorInterceptor(axios) {
  return axios.interceptors.response.use(null, ReportErrorInterceptor);
}
