<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    stroke-width="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 20C23 20 24 26 24 26H8C8 26 9 20 16 20Z"
      stroke-miterlimit="22.9256"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16 17C18.7614 17 21 14.7614 21 12C21 9.23858 18.7614 7 16 7C13.2386 7 11 9.23858 11 12C11 14.7614 13.2386 17 16 17Z"
      stroke-miterlimit="22.9256"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
