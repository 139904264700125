<script setup>
import { useContext } from './context';

const context = useContext();
</script>
<template>
  <svg
    id="global-svg-defs"
    width="0"
    height="0"
    viewBox="0 0 0 0"
  >
    <defs>
      <component
        :is="element"
        v-for="[key, element] in context.elements"
        :key="key"
      />
    </defs>
  </svg>
</template>
